import type { ComponentType } from "react"
import { useEffect, useState } from "react"

interface WithHrefProp {
    href?: string
    title?: string
}

const mergeUTMParameters = (baseURL: string): string => {
    if (typeof window === "undefined") {
        return baseURL
    }

    const urlParams = new URLSearchParams(window.location.search)
    const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
    ]

    const parameters: string[] = utmParams
        .map((param) => {
            const value = urlParams.get(param)
            return value ? `${param}=${value}` : null
        })
        .filter(Boolean) as string[]

    const utmParameters =
        parameters.length > 0 ? `&${parameters.join("&")}` : ""
    return `${baseURL}${utmParameters}`
}

export function hireElasticTeamLink<P extends WithHrefProp>(
    Component: ComponentType<P>
): ComponentType<P> {
    return (props) => (
        <div
            onClick={() =>
                (window.location.href = mergeUTMParameters(
                    "https://distributed.com/signup?ref=nav"
                ))
            }
        >
            <Component {...props} />
        </div>
    )
}

export function detectForCompaniesLink<P extends WithHrefProp>(
    Component: ComponentType<P>
): ComponentType<P> {
    const titleToURLMapping: { [key: string]: string } = {
        "Access Our Talent Cloud":
            "https://www.distributed.com/elastic-teams?ref=nav",
        "What We Do": "https://www.distributed.com/what-we-do?ref=nav",
        "Our Process": "https://www.distributed.com/our-process?ref=nav",
        "Case Studies": "https://www.distributed.com/case-studies?ref=nav",
        Blog: "https://www.distributed.com/blog?ref=nav",
        "Data & AI": "https://www.distributed.com/services/data-and-ai?ref=nav",
        Migration: "https://www.distributed.com/services/migration?ref=nav",
        "Cloud Architecture":
            "https://www.distributed.com/services/cloud-architecture?ref=nav",
        "Explore Our Teams":
            "https://www.distributed.com/teams/explore?ref=nav",
        "Our Talent Cloud": "https://www.distributed.com/talent-cloud?ref=nav",
        "Join our Talent Cloud":
            "https://www.distributed.com/join-elastic-teams?ref=nav",
        "Elastic Team Portal": "https://community.distributed.com/?ref=nav",
        "Current Opportunities": "https://careers.distributed.com/jobs?ref=nav",
        "About Us": "https://www.distributed.com/about?ref=nav",
        "Our Manifesto": "https://www.distributed.com/manifesto?ref=nav",
        "Contact Us": "https://www.distributed.com/contact-us?ref=nav",
        "Privacy Policy": "https://www.distributed.com/privacy-policy?ref=nav",
    }

    const inlineStyles = {
        textDecoration: "none",
        cursor: "pointer",
    }

    const handleRedirect = (url: string) => {
        window.location.href = mergeUTMParameters(url)
    }

    return (props) => {
        const url = titleToURLMapping[props.title]
        if (url) {
            return (
                <div onClick={() => handleRedirect(url)} style={inlineStyles}>
                    <Component {...props} />
                </div>
            )
        } else {
            return <Component {...props} />
        }
    }
}
